/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { message, Spin, Tag, Popconfirm } from 'antd';
import { Formik } from 'formik';
import { Input, Form, Select, DatePicker, Radio } from 'formik-antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { FaFileExcel, FaPencilAlt, FaEye, FaFilePdf, FaRegTrashAlt } from 'react-icons/fa';
import 'moment/locale/pt-br';
import * as Yup from 'yup';
import { dynamicCost, getSuccessMessageByModule } from '~/Utils/index';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import Row from '~/components/Row';
import { Table, TableActions } from '~/components/Table';
import Box from '~/components/Box';
import Pagination from '~/components/Pagination';
import FormControl from '~/components/Form/FormControl';
import { formatPrice } from '~/Utils/format';
import { useAuth } from '~/contexts/auth';
import DefaultLayout from '../_layouts/full';
import { Global, SearchForm } from './styles';
// USADO EM MOVEMENTS
import MovementForm from '../Movement/form';

const initialValues = {
  description: '',
  bank_account_id: '',
  cost_center_id: '',
  cost_id: '',
  initial_date: new Date(new Date().setMonth(new Date().getMonth() - 1)),
  final_date: new Date(),
  value: '',
  paid_out: '',
  filterDateOption: 'pay_date',
};

const initialMetaValues = { input: 0, output: 0, total: 0 };

export default function Review() {
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [meta, setMeta] = useState({});
  const [filter, setFilter] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [costsOptions, setCostsOptions] = useState([]);
  const [bankAccountOptions, setbankAccountOptions] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [metaValues, setMetaValues] = useState(initialMetaValues);
  const currentYear = new Date().getFullYear();
  // const initialDateThreshold = new Date(`${currentYear - 1}-01-01`).setHours(0, 0, 0, 0);
  // const finalDateThreshold = new Date(`${currentYear}-12-31`).setHours(0, 0, 0, 0);
  const context = useAuth();
  const { t } = useTranslation();
  const fiscal = context.user.roles[0] === '@conselhofiscal';

// Função para determinar o intervalo de datas baseado no biênio
const getBiannualDateRange = () => {
  let initialDateThreshold; let finalDateThreshold;

  if (currentYear % 2 === 0) { // Anos pares
    initialDateThreshold = new Date(`${currentYear - 1}-01-01`).setHours(0, 0, 0, 0);
    finalDateThreshold = new Date(`${currentYear}-12-31`).setHours(0, 0, 0, 0);
  } else { // Anos ímpares
    initialDateThreshold = new Date(`${currentYear - 2}-01-01`).setHours(0, 0, 0, 0);
    finalDateThreshold = new Date(`${currentYear - 1}-12-31`).setHours(0, 0, 0, 0);
  }

  return { initialDateThreshold, finalDateThreshold };
};

  // Obter os limites de datas baseado no ano atual
  const { initialDateThreshold, finalDateThreshold } = getBiannualDateRange();

  if (fiscal) {
    initialValues.initial_date = new Date(initialDateThreshold);
    initialValues.initial_date.setDate(initialValues.initial_date.getDate() + 1);
    initialValues.final_date = new Date(finalDateThreshold);
  }

  const handleSearch = async (values, { setErrors }) => {
    setLoading(true);
    try {
      const response = await api.get('/reports?page=1', { params: values });
      setFilter(values);
      setRecordData(response.data.data);
      setMeta(response.data.meta);
      const { input, output, total } = response.data.metrics;
      setMetaValues({
        input: Number(input),
        output: Number(output),
        total: Number(total),
      });
      message.success(t('messages:success'));
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const handleEdit = (id) => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`movements/${id}`);
      message.success(getSuccessMessageByModule(t('screens:movements.titleSingular'), t('messages:successDeleted')));
      setRecordData(recordData.filter((movement) => movement.id !== id));
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async (page) => {
    setLoading(true);
    try {
      const response = await api.get(`reports/?page=${page}`, { params: filter });
      setRecordData(response.data.data);
      setMeta(response.data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchCostCenters = async () => {
    try {
      const { data } = await api.get('/costCenters');
      setCostCenterOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchBankAccounts = async () => {
    try {
      const { data } = await api.get('/bankAccounts');
      setbankAccountOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchCosts = async (canLoading = false) => {
    // eslint-disable-next-line no-unused-expressions
    canLoading && setLoading(true);
    try {
      const { data } = await api.get('/costCenters/costs/all');
      setCostsOptions(data);
    } catch (error) {
      errorHandler(error);
    }
    // eslint-disable-next-line no-unused-expressions
    canLoading && setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchCostCenters(), fetchCosts(), fetchBankAccounts()]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleCostCenterChange = (id) => {
    const selected = costCenterOptions.find((costCenter) => costCenter.id === id);

    if (selected?.costs) {
      setCostsOptions(selected.costs);
    } else {
      setCostsOptions([]);
    }
  };

  const handleDonwload = async () => {
    setLoading(true);
    if (recordData.length > 0) {
      await api
        .get('/reports/export', {
          params: filter,
          method: 'GET',
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `relatorios_${new Date().toLocaleDateString()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } else {
      message.warning(t('messages:noRecordFound'));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchScreenData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadFile = (url) => {
    const element = document.createElement("a");
    element.target = "_blank";
    element.href = url;
    // element.setAttribute("download", url.split('/').pop());
    element.download =  url.split('/').pop()
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click(); // simulate link click
}

  const tableColumns = [
    {
      title: t('screens:movements.data.type'),
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <Tag color={record.type === 'input' ? 'green' : 'volcano'}>{record.type === 'input' ? 'Entrada' : 'Saída'}</Tag>
      ),
    },
    {
      title: t('screens:movements.data.person_id'),
      dataIndex: 'person.name',
      key: 'person.name',
      render: (record, text) => text.person && text.person.name,
    },
    {
      title: t('screens:movements.data.provider_id'),
      dataIndex: 'provider.name',
      key: 'provider.name',
      render: (text, record) =>
        record.provider && record.provider.company_name
          ? record.provider.company_name
          : record.provider && record.provider.contact && record.provider.contact.name,
    },
    {
      title: t('screens:reviews.data.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('screens:reviews.data.bank_statement'),
      dataIndex: 'bank_statement',
      key: 'bank_statement',
    },
    {
      title: t('screens:reviews.data.value'),
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('screens:reviews.data.paid_out')}</div>,
      dataIndex: 'paid_out',
      key: 'paid_out',
      render: (record, text) =>
        text.paid_out ? (
          <div style={{ textAlign: 'center' }}>
            {text.pay_day && (
              <p style={{ fontSize: 10, marginBottom: 5 }}>Pago em: {new Date(text.pay_day).toLocaleDateString()}</p>
            )}
            <Tag color="green">{t('messages:yes')}</Tag>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Tag color="volcano">{t('messages:no')}</Tag>
          </div>
        ),
    },
    {
      title: t('screens:movements.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (

        <TableActions>
          {record.attachment_id ? (
            <Button
              size="small"
              title="Anexo"
              onClick={() => {
                downloadFile(record.attachment_url)
              }}
            >
              <FaFilePdf />
            </Button>
          ) : (
            <Button size="small" title="Anexo" style={{ visibility: 'hidden' }}>
              <FaFilePdf />
            </Button>
          )}
          <Can permission="@movement/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@movement/edit">
            <Can permission="@movement/view">
              <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
          <Can permission="@movement/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];


  const reviewSchema = Yup.object().shape({
    description: Yup.string().nullable(),
    bank_statement: Yup.string().nullable(),
    bank_account_id: Yup.string()
      .nullable()
      .test('bank id', 'error', (value) => {
        if (fiscal) {
          value = 1;
          return value === 1;
        }
        return true;
      }),
    cost_center_id: Yup.string().nullable(),
    cost_id: Yup.string().nullable(),
    filterDateOption: Yup.string()
      .nullable()
      .matches(/(created_date|pay_date|due_date)/, 'Insira um tipo válido!', { excludeEmptyString: true }),

    // paid_out: Yup.boolean().nullable(),
    paid_out: Yup.boolean().nullable().test('paid-out', 'erro', (value) => {
      if (fiscal) {
        // if (value === null || value === undefined) return false;
        value = true;
        return value === true;
      }
      return true;
    } ),

    value: Yup.number().min(1).nullable(),
    initial_date: Yup.date()
      .nullable()
      .test('date-range', `Data inicial deve ser maior que 01/01/${currentYear - 1}`, (value) => { // .test('date-range', 'Data inicial deve ser maior que 01/01/2023', (value) => {
        if (fiscal) {
          if (value === null || value === undefined) return false;
          return value.setHours(0, 0, 0, 0) >= initialDateThreshold; // new Date('2023-01-01').setHours(0, 0, 0, 0);
        }
        return true;
      }),

    final_date: Yup.date()
      .nullable()
      .test('date-range', `Data final deve ser menor que 31/12/${currentYear}`, (value) => { // .test('date-range', 'Data final deve ser menor que 31/12/2024', (value) => {
        if (fiscal) {
          if (value === null || value === undefined) return false;
          return value.setHours(0, 0, 0, 0) <= finalDateThreshold; // new Date(2024, 12, 31).setHours(0, 0, 0, 0);
        }
        return true;
      }),
  });

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:reviews.title')}>
        <Button loading={loading} color="primary" onClick={handleDonwload}>
          <FaFileExcel />
          {t('screens:reviews.donwload')}
        </Button>
      </PageTitle>
      <Box>
        <SearchForm>
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSearch}
            validationSchema={reviewSchema}
          >
            {({ errors, isSubmitting, values, setValues, resetForm, submitForm }) => (
              <Spin spinning={loading || isSubmitting}>
                <Form>
                  <Row>
                    <FormControl
                      cols={{ xs: 4, md: 6, sm: 2, lg: 4.5, xl: 4 }}
                      error={errors.description}
                      field="description"
                      label={t('screens:reviews.data.movement')}
                    >
                      <Input name="description" />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 4, md: 6, sm: 2, lg: 4.5, xl: 4 }}
                      error={errors.provider_person_name}
                      field="provider_person_name"
                      label={t('screens:reviews.data.provider_person_name')}
                    >
                      <Input name="provider_person_name" />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 3, md: 6, sm: 2, lg: 4 }}
                      error={errors.filterDateOption}
                      field="filterDateOption"
                      label={t('screens:reviews.data.filterDateOption')}
                    >
                      {context.user.roles[0] !== '@conselhofiscal' ? (
                        <Select name="filterDateOption" allowClear optionFilterProp="children">
                          <Select.Option key="created_date" value="created_date">
                            {t('screens:reviews.data.created_date')}
                          </Select.Option>
                          <Select.Option key="pay_date" value="pay_date">
                            {t('screens:reviews.data.pay_date')}
                          </Select.Option>
                          <Select.Option key="due_date" value="due_date">
                            {t('screens:reviews.data.due_date')}
                          </Select.Option>
                        </Select>
                      ) : (
                        <Select name="filterDateOption" defaultValue="pay_date">
                          <Select.Option key="pay_date" value="pay_date">
                            {t('screens:reviews.data.pay_date')}
                          </Select.Option>
                        </Select>
                      )}
                    </FormControl>
                    <FormControl
                      cols={{ xs: 2, md: 6, sm: 2, lg: 2.5, xl: 3 }}
                      error={errors.initial_date}
                      field="initial_date"
                      label={t('screens:reviews.data.initial_date')}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        name="initial_date"
                        placeholder={t('messages:select')}
                        locale={locale}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 2, md: 6, sm: 2, lg: 2.5, xl: 3 }}
                      error={errors.final_date}
                      field="final_date"
                      label={t('screens:reviews.data.final_date')}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        name="final_date"
                        placeholder={t('messages:select')}
                        locale={locale}
                      />
                    </FormControl>
                    <FormControl
                      error={errors.paid_out}
                      cols={{ xs: 1.5, md: 3.5, sm: 2.5, lg: 2.5 }}
                      field="paid_out"
                      label={t('screens:reviews.data.paid_out')}
                    >
                      {context.user.roles[0] !== '@conselhofiscal' ? (
                        <Radio.Group name="paid_out" buttonStyle="solid">
                          <Radio.Button value>{t('messages:yes')}</Radio.Button>
                          <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                        </Radio.Group>
                      ) : (
                        <Radio.Group name="paid_out" buttonStyle="solid" value={true}>
                          <Radio.Button value={true}>{t('messages:yes')}</Radio.Button>
                          <Radio.Button value={false} disabled={true}>{t('messages:no')}</Radio.Button>
                        </Radio.Group>
                      )}
                    </FormControl>
                    <FormControl
                      cols={{ xs: 4, md: 6, sm: 2, lg: 3, xl: 4 }}
                      error={errors.bank_account_id}
                      field="bank_account_id"
                      label={t('fields:bank_account_id')}
                    >
                      {/* {context.user.roles[0] !== '@conselhofiscal' ? ( */}
                      <Select name="bank_account_id" allowClear optionFilterProp="children">
                        {bankAccountOptions &&
                          bankAccountOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.description} - {item.bank}
                            </Select.Option>
                          ))}
                      </Select>
                      {/* ) : (
                        <Select name="bank_account_id" defaultValue="1">
                          <Select.Option key="1" value="1" defaultChecked={true}>
                            CC Santander - 33 - Santander
                          </Select.Option>
                        </Select>
                      )} */}
                    </FormControl>
                    <FormControl
                      cols={{ xs: 4, md: 6, sm: 2, lg: 3, xl: 4 }}
                      error={errors.cost_center_id}
                      field="cost_center_id"
                      label={t('fields:cost_center_id')}
                    >
                      <Select
                        name="cost_center_id"
                        onSelect={(id) => {
                          handleCostCenterChange(id);
                          setValues({ ...values, cost_center_id: id, cost_id: '' });
                        }}
                        onClear={async () => {
                          await fetchCosts(true);
                          setValues({ ...values, cost_center_id: '', cost_id: '' });
                        }}
                        allowClear
                        optionFilterProp="children"
                      >
                        {costCenterOptions &&
                          costCenterOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      cols={{ xs: 4, md: 4, sm: 2, lg: 3, xl: 4 }}
                      error={errors.cost_id}
                      field="cost_id"
                      label={t('fields:cost_id')}
                    >
                      <Select name="cost_id" allowClear optionFilterProp="children">
                        {costsOptions &&
                          costsOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      cols={{ xs: 2, md: 3, sm: 2, lg: 2 }}
                      error={errors.value}
                      field="value"
                      label={t('screens:reviews.data.value_gte')}
                    >
                      <Input
                        name="value"
                        onChange={(event) => {
                          setTimeout(() => {
                            setValues({ ...values, value: dynamicCost(event.target.value) });
                          }, 100);
                        }}
                      />
                    </FormControl>
                    {/* extrato bancário */}
                    <FormControl
                      cols={{ xs: 4, md: 6, sm: 2, lg: 4.5, xl: 4 }}
                      error={errors.bank_statement}
                      field="bank_statement"
                      label={t('screens:reviews.data.bank_statement')}
                    >
                      <Input name="bank_statement" />
                    </FormControl>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginRight: '1rem' }}>
                      {metaValues.total !== 0 && (
                        <div style={{ display: 'flex', minWidth: 350, height: 40, alignItems: 'center' }}>
                          <Tag color="green">
                            ENTRADA <br />
                            {formatPrice(metaValues.input)}
                          </Tag>
                          <Tag color="volcano">
                            SAÍDA <br />
                            {formatPrice(metaValues.output)}
                          </Tag>
                          <Tag>
                            TOTAL <br />
                            {formatPrice(metaValues.total)}
                          </Tag>
                        </div>
                      )}
                      <Button
                        type="button"
                        color="default"
                        style={{ marginBottom: '1.5rem', marginLeft: '1rem' }}
                        onClick={() => {
                          resetForm();
                          setMetaValues(initialMetaValues);
                        }}
                      >
                        Limpar
                      </Button>
                      <Button type="submit" color="primary" style={{ marginBottom: '1.5rem', marginLeft: '1rem' }}>
                        Pesquisar
                      </Button>
                    </div>
                  </Row>
                </Form>
                {/* show only if not fiscal */}
                {!fiscal && (
                <MovementForm
                  visible={showForm}
                  movementID={selectedRecord}
                  onClose={() => {
                    setShowForm(false);
                    submitForm();
                  }}
                />
                )}
              </Spin>
            )}
          </Formik>
        </SearchForm>
        <Table
          pageSize={100}
          pagination={false}
          rowKey="id"
          loading={loading}
          dataSource={recordData}
          columns={tableColumns}
        />
        <Pagination
          onChange={(value) => {
            handleChangePage(value);
          }}
          meta={meta}
        />

        <Global />
      </Box>
    </DefaultLayout>
  );
}
