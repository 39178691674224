import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

export default function Pagination({ children, meta, ...props }) {
  const { t } = useTranslation();
  const { from, to, total, current_page } = meta;

  return (
    <>
      {total > 0 && (
        <Container
          {...props}
          showTotal={
            () =>
              `${t('screens:pagination.showing')} ${from} ${t('screens:pagination.to')} ${to} ${t(
                'screens:pagination.of'
              )} ${total} ${t('screens:pagination.records')}`
            // eslint-disable-next-line react/jsx-curly-newline
          }
          showSizeChanger={false}
          total={total}
          defaultPageSize={10}
          current={current_page}
        >
          {children}
        </Container>
      )}
    </>
  );
}

Pagination.propTypes = {
  children: PropTypes.node,
  meta: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

Pagination.defaultProps = {
  children: null,
  meta: {
    from: 0,
    to: 0,
    current_page: 1,
    total: 0,
  },
};
