/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Modal, Spin, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import Box from '~/components/Box';
import FormControl from '~/components/Form/FormControl';
import { getColorByAbstractStatus } from '~/Utils';
import { ModalFooter } from '~/components/Modal';

export default function AbstractForm({ visible, onClose, resume }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      setRecordData(resume);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Formik validateOnBlur={false} validateOnChange={false} initialValues={recordData} enableReinitialize>
      {({ errors, isSubmitting, resetForm }) => (
        <Modal
          title={`${t('screens:viewing')} - #${recordData.id} - ${t('screens:abstracts.sent_at')} ${new Date(
            recordData.created_at
          ).toLocaleString()}`}
          onCancel={onClose}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="70%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter type="info" loading={loading || isSubmitting} onCancel={onClose} />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Box>
                <Row>
                  <FormControl cols={{ xl: 3, xs: 16, sm: 12 }} label={t('screens:abstracts.data.status')}>
                    <Tag color={getColorByAbstractStatus(recordData.status)}>{recordData.status}</Tag>
                  </FormControl>
                </Row>
                {recordData.evaluator_observation && (
                  <Row>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.evaluator_observation}
                      field="evaluator_observation"
                      label={t('screens:abstracts.data.evaluator_observation')}
                    >
                      <Input.TextArea disabled name="evaluator_observation" />
                    </FormControl>
                  </Row>
                )}
                <Row>
                  <FormControl
                    cols={{ xl: 12, xs: 24, sm: 12 }}
                    error={errors.congress_id}
                    label={t('fields:congress_id')}
                  >
                    <Input name="congress.description" disabled />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 12, xs: 24, sm: 12 }}
                    error={errors.subarea_id}
                    label={t('fields:subarea_id')}
                  >
                    <Input name="subArea.name" disabled />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 24 }}
                    disabled
                    error={errors.title}
                    label={t('screens:abstracts.data.title')}
                  >
                    <Input name="title" disabled />
                  </FormControl>
                  <FormControl
                    cols={{ xs: 24 }}
                    error={errors.resume}
                    field="resume"
                    label={t('screens:abstracts.data.abstract')}
                  >
                    <Input.TextArea rows={5} name="resume" disabled />
                  </FormControl>
                </Row>
                <Row>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.word_1}
                    label={`${t('screens:abstracts.data.word')} 1`}
                  >
                    <Input name="word_1" disabled />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.word_2}
                    label={`${t('screens:abstracts.data.word')} 2`}
                  >
                    <Input name="word_2" disabled />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.word_3}
                    label={`${t('screens:abstracts.data.word')} 3`}
                  >
                    <Input name="word_3" disabled />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.word_4}
                    label={`${t('screens:abstracts.data.word')} 4`}
                  >
                    <Input name="word_4" disabled />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.word_5}
                    label={`${t('screens:abstracts.data.word')} 5`}
                  >
                    <Input name="word_5" disabled />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 4, xs: 24, sm: 12 }}
                    error={errors.uf}
                    label={t('screens:abstracts.data.uf')}
                  >
                    <Input name="uf" disabled />
                  </FormControl>
                </Row>

                <Row>
                  <FormControl
                    cols={{ xl: 12, xs: 24, sm: 24 }}
                    error={errors.acknowledgement}
                    label={t('screens:abstracts.data.acknowledgement')}
                  >
                    <Input name="acknowledgement" disabled />
                  </FormControl>
                  <FormControl
                    cols={{ xl: 12, xs: 24, sm: 24 }}
                    error={errors.sponsors}
                    label={t('screens:abstracts.data.sponsors')}
                  >
                    <Input name="sponsors" disabled />
                  </FormControl>
                </Row>
              </Box>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
