/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, DatePicker, Radio } from 'formik-antd';
import { Modal, message, Spin, Col, Upload, Space } from 'antd';
import { FaUpload, FaMinus, FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';

import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import Thumb from '~/components/Thumb';
import Can from '~/components/Can';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import Button from '~/components/Button';
import { decrypt, dynamicCost, getBase64, getSuccessMessageByModule } from '~/Utils/index';
import InputCurrency from '~/components/Form/InputCurrency';

import api from '~/services/api';

const initialValues = {
  description: '',
  type: 'input',
  value: '',
  paid_out: '',

};

export default function MovementForm({ visible, onClose, movementID, personId, personName }) {
  console.log('movementID: ', movementID, 'personId: ', personId);
  initialValues.person_id = personId;
  initialValues.person_name = personName;

  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [bankAccountOptions, setbankAccountOptions] = useState([]);
  const [providersOptions, setProvidersOptions] = useState([]);
  const [costsOptions, setCostsOptions] = useState([]);
  const [file, setFile] = useState(null);
  const [deleteOldFile, setDeleteOldFile] = useState(false);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);

    delete values.isEdit;
    delete values.person_name;

    try {
      if (values.id) {
        if (deleteOldFile === true) {
          await api.delete(`/files/${values.id}/attachment`);
          values.attachment_id = null;
        }
        await api.put(`/movements/${values.id}`, values);
        message.success(getSuccessMessageByModule(t('screens:movements.titleSingular'), t('messages:successUpdated')));
        if (file && file.originFileObj) {
          const files = new FormData();
          files.append('file', file.originFileObj);
          await api.post(`/files/${values.id}/attachment`, files);
        }
        // add provider name to the list
        if (values.provider_id) {
          const provider = providersOptions.find((item) => item.id === values.provider_id);
          values.provider = provider;
        } else {
          values.provider = null;
        }
        onClose(values);

      } else {
        const { data } = await api.post('/movements', values);
        message.success(getSuccessMessageByModule(t('screens:movements.titleSingular'), t('messages:successCreated')));
        if (file && file.originFileObj) {
          const files = new FormData();
          files.append('file', file.originFileObj);
          await api.post(`/files/${data.id}/attachment`, files);
        }
        if (data.provider_id) {
          const provider = providersOptions.find((item) => item.id === values.provider_id);
          data.provider = provider;
        } else {
          data.provider = null;
        }
        onClose(data);
      }
      setFile(null);
      setDeleteOldFile(null);

    } catch (error) {
      setErrors(errorHandler(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchRecords = async () => {
    try {
      const response = await api.get(`/movements/${movementID}`);
      const data = JSON.parse(response.data);

      if (data?.cost_center?.costs) {
        setCostsOptions(data.cost_center.costs);
      }

      setFile(data.attachment);
      if (document.getElementById('uploadButton')) {
        document.getElementById('uploadButton').style.cursor = data.attachment !== null ? 'no-drop' : 'pointer';
      }
      setRecordData(data);
    } catch (error) {
      errorHandler(error);
    }
  };
  const fetchCostCenters = async () => {
    try {
      const { data } = await api.get('/costCenters');
      setCostCenterOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleCostCenterChange = (id) => {
    const selected = costCenterOptions.find((costCenter) => costCenter.id === id);

    if (selected?.costs) {
      setCostsOptions(selected.costs);
    } else {
      setCostsOptions([]);
    }
  };

  const fetchProviders = async () => {
    try {
      const { data } = await api.get('/providers');
      setProvidersOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchBankAccounts = async () => {
    try {
      const { data } = await api.get('/bankAccounts');
      setbankAccountOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchCostCenters(), fetchBankAccounts(), fetchProviders()]); // , fetchPeople() removed
      if (!movementID) {
        setCostsOptions([]);
        setRecordData(initialValues);
      } else {
        await fetchRecords();
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleRemoveFile = () => {
    if (document.getElementById('uploadButton')) {
      document.getElementById('uploadButton').style.cursor = 'pointer';
    }
    setFile(null);
    setDeleteOldFile(true);
  };

  const beforeUpload = (newFile) => {
    setLoading(true);

    let justOne = true;
    if (file) {
      justOne = false;
      message.error('Você só pode anexar 1 arquivo');
    }

    const isValid =
      newFile.type === 'image/jpeg' ||
      newFile.type === 'image/jpg' ||
      newFile.type === 'image/png' ||
      newFile.type === 'application/pdf';
    if (!isValid) {
      message.error('Você só pode anexar arquivos do tipo: JPG/JPEG/PNG/PDF');
      setTimeout(() => {
        handleRemoveFile();
      }, 100);
    }
    const isLt2M = newFile.size / 1024 / 1024 < 20;
    if (!isLt2M) {
      message.error('O arquivo deve ter menos de 20MB!');
      setTimeout(() => {
        handleRemoveFile();
      }, 100);
    }

    setLoading(false);
    return isValid && isLt2M && justOne;
  };

  const handleUpload = (event) => {
    setLoading(true);

    if (!event.file.originFileObj) {
      setLoading(false);
      return;
    }

    let extension = '';
    let name = '';
    if (event.file.name.substring(event.file.name.length - 4) === 'jpeg') {
      extension = event.file.name.substring(event.file.name.length - 5);
      name = event.file.name.replaceAll('.', '');
      name = name.substring(0, name.length - 4);
    } else {
      extension = event.file.name.substring(event.file.name.length - 4);
      name = event.file.name.replaceAll('.', '');
      name = name.substring(0, name.length - 3);
    }
    event.file.name = name + extension;

    if (!event.file.id) {
      getBase64(event.file.originFileObj, (image) => {
        event.file.base64Url = image;
      });
    }
    document.getElementById('uploadButton').style.cursor = 'no-drop';
    setTimeout(() => {
      setFile(event.file);
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const movementsSchema = Yup.object().shape({
    description: Yup.string().required(),
    cost_id: Yup.string().nullable(),
    cost_center_id: Yup.number().integer().required(),
    type: Yup.string()
      .required()
      .matches(/(input|output)/, 'Insira um tipo válido!', { excludeEmptyString: true }),
    paid_out: Yup.boolean().required(),
    value: Yup.number().min(1).required(),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={movementsSchema}
    >
      {({ errors, isSubmitting, submitForm, resetForm, setValues, values }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.description}` : t('screens:movements.title')}
          onCancel={onClose}
          style={{ maxWidth: '850px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="100%"
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <ModalFooter
              type={permissions && !permissions.includes('@movement/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          }
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    {/* type */}
                    <FormControl
                      error={errors.type}
                      cols={{ xs: 24 }}
                      field="type"
                      label={t('screens:movements.data.type')}
                      required
                    >
                      <Radio.Group name="type" buttonStyle="solid">
                        <Radio.Button value="input">
                          <FaPlus /> {t('screens:movements.data.input')}
                        </Radio.Button>
                        <Radio.Button value="output">
                          <FaMinus /> {t('screens:movements.data.output')}
                        </Radio.Button>
                      </Radio.Group>
                    </FormControl>
                    {/* person */}
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.person_id}
                      field="person_id"
                      label={t('fields:person_id')}
                    >
                      <Space direction="horizontal">
                        <span style={{ color: 'green' }}>{values.person_name ? values.person_name : personName}</span>
                        <Input name="person_id" type="hidden" />
                      </Space>
                    </FormControl>
                    {/* supplier */}
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.provider_id}
                      field="provider_id"
                      label={t('fields:provider_id')}
                    >
                      <Select name="provider_id" showSearch allowClear optionFilterProp="children">
                        <Select.Option value={null}>Nenhum</Select.Option>
                        {providersOptions &&
                          providersOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.business ? item.company_name : item.contact && item.contact.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    {/* cost center */}
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.cost_center_id}
                      field="cost_center_id"
                      label={t('fields:cost_center_id')}
                    >
                      <Select
                        name="cost_center_id"
                        onSelect={(id) => {
                          handleCostCenterChange(id);
                          setValues({ ...values, cost_center_id: id, cost_id: '' });
                        }}
                        onClear={() => {
                          setValues({ ...values, cost_id: '' });
                          setCostsOptions([]);
                        }}
                        allowClear
                        optionFilterProp="children"
                      >
                        {costCenterOptions &&
                          costCenterOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    {/* cost */}
                    <FormControl cols={{ xs: 12 }} error={errors.cost_id} field="cost_id" label={t('fields:cost_id')}>
                      <Select name="cost_id" allowClear optionFilterProp="children">
                        {costsOptions &&
                          costsOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    {/* description */}
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.description}
                      field="description"
                      label={t('screens:movements.data.description')}
                      required
                    >
                      <Input name="description" disabled={permissions && !permissions.includes('@movement/edit')} />
                    </FormControl>
                    {/* bank account */}
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.bank_account_id}
                      field="bank_account_id"
                      label={t('fields:bank_account_id')}
                    >
                      <Select name="bank_account_id" allowClear optionFilterProp="children">
                        {bankAccountOptions &&
                          bankAccountOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.description} - {item.bank}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    {/* bank statement */}
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.bank_statement}
                      field="bank_statement"
                      label={t('screens:movements.data.bank_statement')}
                    >
                      <Input name="bank_statement" disabled={permissions && !permissions.includes('@movement/edit')} />
                    </FormControl>
                    {/* due date */}
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.due_date}
                      field="due_date"
                      label={t('screens:movements.data.due_date')}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        name="due_date"
                        placeholder={t('messages:select')}
                        locale={locale}
                      />
                    </FormControl>
                    {/* pay day */}
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.pay_day}
                      field="pay_day"
                      label={t('screens:movements.data.pay_day')}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        name="pay_day"
                        placeholder={t('messages:select')}
                        locale={locale}
                      />
                    </FormControl>
                    {/* value */}
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.value}
                      field="value"
                      label={t('screens:movements.data.value')}
                      required
                    >
                      <InputCurrency
                        name="value"
                        currency="R$"
                        number={false}
                        maxLength="9"
                        disabled={permissions && !permissions.includes('@movement/edit')}
                        onChange={(event) => {
                          setTimeout(() => {
                            setValues({ ...values, value: dynamicCost(event.target.value) });
                          }, 100);
                        }}
                      />
                    </FormControl>
                    {/* paid out */}
                    <FormControl
                      error={errors.paid_out}
                      cols={{ xs: 12 }}
                      field="paid_out"
                      label={t('screens:movements.data.paid_out')}
                      required
                    >
                      <Radio.Group name="paid_out" buttonStyle="solid">
                        <Radio.Button value>{t('messages:yes')}</Radio.Button>
                        <Radio.Button value={false}>{t('messages:no')}</Radio.Button>
                      </Radio.Group>
                    </FormControl>
                  </Row>
                  <Row>
                    <Can permission="@people/edit">
                      <Upload
                        disabled={file}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleUpload}
                      >
                        <Button style={{ marginLeft: '1rem' }} id="uploadButton" color="default">
                          Anexar arquivo
                          <FaUpload />
                        </Button>
                      </Upload>
                    </Can>
                  </Row>
                  <Row>
                    <Thumb
                      file={file}
                      fileURL={recordData.attachment_url}
                      onDelete={() => {
                        handleRemoveFile();
                      }}
                    />
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
