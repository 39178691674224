/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  message,
  Spin,
  Col,
  Tag,
  Row,
  Descriptions,
  Typography,
  Modal,
  Space,
  Card,
  Button,
  Input,
  InputNumber,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Pagination from '~/components/Pagination';
import { formatPrice } from '~/Utils/format';
import DefaultLayout from '~/pages/_layouts/full';
import errorHandler from '~/Utils/errorHandler';
import { Table, TableHeader } from '~/components/Table';
import Box from '~/components/Box';
import PageTitle from '~/components/PageTitle';
import api from '~/services/api';
import refuseCodes from '~/data/refuseCodes.json';
import { Global } from './styles';
import Can from '~/components/Can';

const { Text, Title } = Typography;

const statusColor = (status) => {
  switch (status) {
    case 'AUTHORIZED':
    case 'PAID':
    case 'AVAILABLE':
    case 'DEBITED':
      return 'green';
    case 'IN_ANALYSIS': // Indicates that the buyer has chosen to pay with a credit card and PagBank is analyzing the risk of the transaction.
    case 'WAITING': // Means the charge is awaiting payment
    case 'UP_FOR_GRABS':
    case 'TEMPORARY_RETENTION':
      return 'orange';
    case 'EXPIRED': // Indicates that the Checkout has expired. The expiration occurs for a default time of 2 hours, if you want to customize this time it is possible by sending the parameter expiration_date
    case 'CANCELED':
    case 'DECLINED': // Indicates that the charge was denied by PagBank or Issuer.
    case 'RETURNED':
      return 'red';
    default:
      return 'GREY';
  }
};

const translateNameStatus = (status) => {
  switch (status) {
    case 'AUTHORIZED':
      return 'PAGO'; // 'AUTORIZADO';
    case 'PAID':
      return 'PAGO';
    case 'IN_ANALYSIS':
      return 'EM ANÁLISE';
    case 'WAITING':
      return 'AGUARDANDO';
    case 'EXPIRED':
      return 'EXPIRADO';
    case 'CANCELED':
      return 'CANCELADO';
    case 'DECLINED':
      return 'NEGADO';
    // pos-transactional
    case 'AVAILABLE':
      return 'PAGO'; // 'DISPONÍVEL';
    case 'UP_FOR_GRABS':
      return 'EM DISPUTA';
    case 'RETURNED':
      return 'DEVOLVIDO';
    case 'DEBITED':
      return 'PAGO'; // 'DEBITADO';
    case 'TEMPORARY_RETENTION':
      return 'RETENÇÃO TEMPORÁRIA';
    case null || undefined || '':
      return 'N/A';
    default:
      return status.toUpperCase();
  }
};

// return the message based on the refuse code, located in refuseCodes.json array of objects
const getRefuseMessage = (code) => {
  const message2 = refuseCodes.find((refuse) => refuse.code.toString() === code.toString());
  if (message2) {
    return message2?.message;
  }
  return 'N/A';
};

export default function PersonTransactions({ personId }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [meta, setMeta] = useState({});

  const handleExport = async () => {
    setLoading(true);
    try {
      await api
        .get('/orders/export/all', {
          method: 'get',
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `orders_${new Date().toLocaleDateString()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      if (error.response?.status === 400) {
        message.warning('Nenhum registro encontrado.');
      }
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await api.get(`/orders/person/${personId}?page=${page}`, {
        params: {
          search: searchTerm || null,
        },
      });
      const { data } = response;
      console.log('data transaction user: ', data);
      setRecordData(data.data);
      setMeta(data.meta);
      // message.success(`Página ${page} carregada com sucesso!`);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const refund = async (orderId, chargeId, amount) => {
    try {
      setLoading(true);
      const { data } = await api.post(`/orders/charges/refund`, {
        amount: amount,
        chargeId: chargeId,
        orderId: orderId,
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const tableColumns = [
    {
      key: 'id',
      title: t('screens:orders.data.order_id'),
      dataIndex: 'order_id',
      align: 'left',
      ellipsis: {
        showTitle: true,
      },
      render: (text, record) => {
        // remove the first 3 characters from the id
        const id = text.substring(5);
        return id;
      },
    },
    {
      key: 'customer.name',
      title: t('screens:orders.data.customerName'),
      dataIndex: 'customer.name',
      align: 'left',
      ellipsis: {
        showTitle: true,
      },
      render: (text, record) => record.customer.name,
    },
    {
      title: t('screens:orders.data.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      align: 'left',
      // width: 200,
      ellipsis: {
        showTitle: true,
      },
      render: (_, record) =>
        `${new Date(record.created_at).toLocaleDateString()} às ${new Date(record.created_at).toLocaleTimeString()}`,
    },
    {
      title: t('screens:orders.data.payment_method_type'),
      dataIndex: 'payment_method_type',
      key: 'payment_method_type',
      align: 'left',
      // sorter: true,
      // width: 200,
      ellipsis: {
        showTitle: true,
      },
      render: (text, record) => (
        // change color based on method type (credit card, boleto or pix)
        <Tag
          color={
            record.payment_method_type === 'CREDIT_CARD'
              ? 'purple'
              : record.payment_method_type === 'BOLETO'
              ? 'pink'
              : 'blue'
          }
        >
          {record.payment_method_type === 'CREDIT_CARD'
            ? 'CARTÃO DE CRÉDITO'
            : record.payment_method_type === 'BOLETO'
            ? 'BOLETO'
            : 'PIX'}
        </Tag>
      ),
    },
    // {
    //   title: t('screens:orders.data.items'),
    //   dataIndex: 'items',
    //   key: 'items',
    //   render: (text, record) => {
    //     const items = record.items.map((item) => (
    //       <>
    //         <p>{item.name}</p>
    //         <p>{item.unit_amount}</p>
    //       </>
    //     ));
    //     return items;
    //   },
    // },
    {
      title: t('screens:orders.data.itemName'),
      dataIndex: 'items.name',
      key: 'items.name',
      ellipsis: {
        showTitle: true,
      },
      render: (text, record) => record.items[0].name,
    },
    {
      title: t('screens:orders.data.totalAmount'),
      dataIndex: 'items.unit_amount',
      key: 'items.unit_amount',
      ellipsis: {
        showTitle: true,
      },
      render: (text, record) => {
        // paid value and refunded value if exists
        if (!record.charges) {
          return <p>{formatPrice(record.items[0].unit_amount / 100)}</p>;
        }
        // const totalCharge = record.charges[0].amount.value;
        const { total } = record.charges[0].amount.summary;
        const { refunded } = record.charges[0].amount.summary;
        // const { paid } = record.charges[0].amount.summary;
        return (
          <>
            <p>{formatPrice(total / 100)}</p>
            {refunded > 0 ? <p>(Reembolsado: {formatPrice(refunded / 100)})</p> : null}
            {/* <p>Pago: {formatPrice(paid / 100)}</p> */}
          </>
        );
      },
    },
    {
      title: t('screens:orders.data.charges'),
      dataIndex: 'charges',
      key: 'charges',
      align: 'center',
      ellipsis: {
        showTitle: true,
      },
      render: (text, record) => {
        if (!record.charges) {
          return <p>N/A</p>;
        }
        const charges = record?.charges.map((charge) => (
          <>
            <p>
              <Tag color={statusColor(charge.status)}>{translateNameStatus(charge.status)}</Tag>
            </p>
            {charge.status === 'DECLINED' && charge?.payment_response?.code && (
              <>
                <p
                  style={{ color: statusColor(charge.status), fontSize: 10, marginBottom: '5px' }}
                >{`${charge.payment_response.code} - ${charge.payment_response.message}`}</p>
                <p style={{ color: statusColor(charge.status), fontSize: 10, marginTop: '0px' }}>
                  ({getRefuseMessage(charge.payment_response.code)})
                </p>
              </>
            )}
            {charge.paid_at !== null && charge.paid_at !== undefined ? (
              <p>
                (
                {`${new Date(charge.paid_at).toLocaleDateString()} às ${new Date(charge.paid_at).toLocaleTimeString()}`}
                )
              </p>
            ) : null}
          </>
        ));
        return charges;
      },
    },
  ];

  const expandedRowRender = (record) => (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Row gutter={16}>
        <Col span={24}>
          {/* <Space direction="vertical" style={{ display: 'flex' }}> */}
          <Descriptions
            title={<Title level={5}>DETALHES DA TRANSAÇÃO</Title>}
            layout="vertical"
            bordered={true}
            colon={false}
            size="small"
            column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="ORDER ">
              <b>SBFV Id: </b> {record.id}
              <br />
              <b>Ordem Id: </b> {record.order_id}
              <br />
              <b>Criada em </b>{' '}
              {`${new Date(record.created_at).toLocaleDateString()} às ${new Date(
                record.created_at
              ).toLocaleTimeString()}`}
              <br />
              <b>Alterada em: </b>{' '}
              {`${new Date(record.updated_at).toLocaleDateString()} às ${new Date(
                record.updated_at
              ).toLocaleTimeString()}`}
            </Descriptions.Item>
            <Descriptions.Item label="ITEM">
              <b>Nome: </b> {record.items[0].name}
              <br />
              <b>Quantidade: </b> {record.items[0].quantity}
              <br />
              <b>Referência: </b> {record.items[0].reference_id}
              <br />
              <b>Valor Unitário: </b> {formatPrice(record.items[0].unit_amount / 100)}
            </Descriptions.Item>
            <Descriptions.Item label="CLIENTE">
              <b>Nome: </b> {record.customer.name}
              <br />
              <b>E-mail: </b> {record.customer.email}
              <br />
              {record.customer.phones.map((phone) => (
                <>
                  <b>Telefone {phone.type === 'MOBILE' ? ' Celular' : ' Fixo'}: </b> {phone.country} {phone.area}{' '}
                  {phone.number}
                  <br />
                </>
              ))}
              <b>CPF/CNPJ (TaxId): </b> {record.customer.tax_id}
            </Descriptions.Item>
            {record.shipping !== null ? (
              <Descriptions.Item label="ENDEREÇO DE ENTREGA">
                <b>Logradouro: </b>
                {record.shipping.address.street} <br />
                <b>Número: </b> {record.shipping.address.number} <br />
                <b>Localidade: </b> {record.shipping.address.locality} <br />
                <b>Cidade: </b> {record.shipping.address.city}-{record.shipping.address.region_code} <br />
                <b>País: </b> {record.shipping.address.country} <br />
                <b>Código Postal: </b> {record.shipping.address.postal_code}
              </Descriptions.Item>
            ) : null}
          </Descriptions>
          {/* </Space> */}
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>COBRANÇAS</Col>
        {record.charges !== null ? (
          <>
            {record.charges.map((charge) => (
              <>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  {/* CHARGES */}
                  <Card
                    title={<Text style={{ fontSize: 14 }}>ID {charge.id}</Text>}
                    bordered
                    bodyStyle={{ padding: 0, backgroundColor: '#ffffff' }}
                    style={{ fontSize: 12, backgroundColor: '#fafafa' }}
                  >
                    <Descriptions
                      layout="horizontal"
                      bordered
                      column={1}
                      contentStyle={{ backgroundColor: '#ffffff' }}
                      labelStyle={{ backgroundColor: '#ffffff', paddingLeft: 40, width: 260, fontWeight: 'bold' }}
                      size="small"
                    >
                      <Descriptions.Item label="Status">
                        <Tag color={statusColor(charge.status)}>{translateNameStatus(charge.status)}</Tag>
                        {charge.status === 'DECLINED' && charge?.payment_response?.code && (
                          <>
                            <p> </p>
                            <p
                              style={{ color: statusColor(charge.status), fontSize: 12, marginBottom: '5px' }}
                            >{`${charge.payment_response.code} - ${charge.payment_response.message}`}</p>
                            <p style={{ color: statusColor(charge.status), fontSize: 12, marginTop: '0px' }}>
                              ({getRefuseMessage(charge.payment_response.code)})
                            </p>
                          </>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Paga em">
                        {charge.paid_at
                          ? `${new Date(charge.paid_at).toLocaleDateString()} às ${new Date(
                              charge.paid_at
                            ).toLocaleTimeString()}`
                          : 'N/A'}
                      </Descriptions.Item>
                      {/* <Descriptions.Item label="Paga em">{`${new Date(charge.paid_at).toLocaleDateString()} às ${new Date(charge.paid_at).toLocaleTimeString()}`}</Descriptions.Item> */}
                      <Descriptions.Item label="Criada em">{`${new Date(
                        charge.created_at
                      ).toLocaleDateString()} às ${new Date(
                        charge.created_at
                      ).toLocaleTimeString()}`}</Descriptions.Item>
                      <Descriptions.Item label="Descrição">{charge.description}</Descriptions.Item>
                      <Descriptions.Item label="Reference Id">{charge.reference_id}</Descriptions.Item>
                      <Descriptions.Item label="Quantia">
                        <>
                          <b>Valor:</b> {formatPrice(charge.amount.value / 100)}
                        </>
                        <br />
                        <>
                          <b>Total:</b> {formatPrice(charge.amount.summary.total / 100)}
                        </>
                        <br />
                        <>
                          <b>Pago:</b> {formatPrice(charge.amount.summary.paid / 100)}
                        </>
                        <br />
                        <>
                          <b>Reembolsado:</b> {formatPrice(charge.amount.summary.refunded / 100)}
                        </>
                      </Descriptions.Item>
                      <Descriptions.Item label="Método de Pagamento">
                        {charge.payment_method.type === 'CREDIT_CARD' ? (
                          <>
                            <Tag color="purple">CARTÃO DE CRÉDITO</Tag>
                            <br />
                            <b>Bandeira: </b> {charge.payment_method.card.brand}
                            <br />
                            <b>Número: </b> {charge.payment_method.card.first_digits}
                            ...
                            {charge.payment_method.card.last_digits}
                            <br />
                            <b>Nome do titular: </b> {charge.payment_method.card.holder.name}
                            <br />
                            <b>Expiração: </b> {charge.payment_method.card.exp_month}/
                            {charge.payment_method.card.exp_year}
                          </>
                        ) : null}
                        {charge.payment_method.type === 'BOLETO' ? (
                          <>
                            <Tag color="pink">BOLETO</Tag>
                            <br />
                            <b>Id: </b> {charge.payment_method.boleto.id}
                            <br />
                            <b>Nome do titular: </b> {charge.payment_method.boleto.holder.name}
                            <br />
                            <b>E-mail: </b> {charge.payment_method.boleto.holder.email}
                            <br />
                            <b>CPF/CNPJ: </b> {charge.payment_method.boleto.holder.tax_id}
                            <br />
                            {/* <b>Logradouro: </b> {charge.payment_method.boleto.holder.address.street}
                            <br />
                            <b>Número: </b> {charge.payment_method.boleto.holder.address.number}
                            <br />
                            <b>Localidade: </b> {charge.payment_method.boleto.holder.address.locality}
                            <br />
                            <b>Cidade: </b> {charge.payment_method.boleto.holder.address.city}-
                            {charge.payment_method.boleto.holder.address.region_code}
                            <br />
                            <b>País: </b> {charge.payment_method.boleto.holder.address.country}
                            <br />
                            <b>Código Postal: </b> {charge.payment_method.boleto.holder.address.postal_code}
                            <br /> */}
                            <b>Vencimento: </b> {charge.payment_method.boleto.due_date}
                            <br />
                            <b>Linha digitável: </b>
                            {charge.payment_method.boleto.formatted_barcode}
                            <br />
                            {/* <b>Instruções: </b> {charge.payment_method.boleto.instruction_lines.line_1}
                            <br />
                            <b> </b> {charge.payment_method.boleto.instruction_lines.line_2}
                            <br /> */}
                            <b>Instruções (linha 1): </b> {charge.payment_method.boleto.instruction_lines.line_1}
                            <br />
                            <b>Instruções (linha 2): </b> {charge.payment_method.boleto.instruction_lines.line_2}
                            <br />
                            <br />
                          </>
                        ) : null}
                        {charge.payment_method.type === 'PIX' ? (
                          <>
                            <Tag color="blue">PIX</Tag>
                          </>
                        ) : null}
                      </Descriptions.Item>
                      {charge.amount.summary.paid > charge.amount.summary.refunded &&
                      (record.status !== 'CANCELED' || record.status !== 'WAITING') ? (
                        <Descriptions.Item label="Reembolso/ Cancelamento">
                          {/* formulário com campo valor e botão para reembolso (refund), valor não pode ser maior que o valor pago charge.amount.value */}

                          <Input.Group compact>
                            <InputNumber
                              name="amount"
                              id="amount"
                              min={0}
                              max={charge.amount.value}
                              defaultValue={0}
                              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                              style={{ width: 200 }}
                            />
                            <Button
                              type="primary"
                              onClick={() => {
                                Modal.confirm({
                                  title: 'Tem certeza que deseja reembolsar?',
                                  // icon: <FaExclamationCircle color='orange' size='25' />,
                                  content: 'Esta ação não pode ser desfeita!',
                                  okText: 'Sim',
                                  cancelText: 'Não',
                                  onOk: () => {
                                    refund(record.order_id, charge.id, document.getElementById('amount').value);
                                  },
                                });
                              }}
                            >
                              Reembolsar
                            </Button>
                          </Input.Group>
                          <br />
                          <Button
                            type="danger"
                            onClick={() => {
                              Modal.confirm({
                                title: 'Tem certeza que deseja canelar?',
                                content: 'Esta ação não pode ser desfeita!',
                                okText: 'Sim',
                                cancelText: 'Não',
                                onOk: () => {
                                  refund(
                                    record.order_id,
                                    charge.id,
                                    charge.amount.value - charge.amount.summary.refunded
                                  );
                                },
                              });
                            }}
                          >
                            Cancelar Pagamento
                          </Button>
                        </Descriptions.Item>
                      ) : null}
                    </Descriptions>
                  </Card>
                </Col>
              </>
            ))}
          </>
        ) : null}
      </Row>
    </Space>
  );

  return (
    <>
      {/* <Global />
      <PageTitle title="TRANSAÇÕES"> */}
        <Button
          // make this button not visible for now
          style={{ display: 'inline-block', marginRight: '0.5rem', visibility: 'hidden' }}
          color="primary"
          loading={loading}
          onClick={() => handleExport()}
        >
          {/* <FaFileExcel /> */}
          {t('screens:people.btnExport')}
        </Button>
      {/* </PageTitle> */}
      <Spin spinning={loading}>
        <>
          <TableHeader>
            <Input.Search onSearch={(value) => setSearchTerm(value)} className="search-field" />
          </TableHeader>
          <Table
            pagination={false}
            columns={tableColumns}
            rowKey="id"
            expandable={{ expandedRowRender }}
            loading={loading}
            dataSource={recordData}
          />
          <Pagination
            onChange={(value) => {
              fetchData(value);
            }}
            meta={meta}
          />
        </>
      </Spin>
    </>
  );
}
