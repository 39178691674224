/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { FaPlus, FaRegTrashAlt, FaPencilAlt, FaEye, FaFilePdf, FaRegNewspaper } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input, message, Popconfirm, Tag } from 'antd';
import { getSuccessMessageByModule } from '~/Utils';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { TableHeader, Table, TableActions } from '~/components/Table';
import Pagination from '~/components/Pagination';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import moment from 'moment';
// USADO EM REPORTS TAMBÉM
import DefaultLayout from '../_layouts/full';
import MovementForm from './movementForm';
import { Global } from './styles';

export default function PersonMovements({ personId, personName }) {
  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [meta, setMeta] = useState({});
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/movements/person/${personId}`, {
        params: {
          page: 1,
          search: searchTerm || null,
        },
      });
      const { data } = response;
      setMeta(data.meta);
      setRecordData(data.data);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleChangePage = async (value) => {
    setLoading(true);
    try {
      const response = await api.get(`/movements/person/${personId}`, {
        params: {
          page: value,
          search: searchTerm || null,
        },
      });
      setRecordData(response.data.data);
      setMeta(response.data.meta);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`movements/${id}`);
      message.success(getSuccessMessageByModule(t('screens:movements.titleSingular'), t('messages:successDeleted')));
      await handleSearch();
    } catch (error) {
      errorHandler(error);
    }
    // remove the record from the list
    setRecordData(recordData.filter((item) => item.id !== id));

    setLoading(false);
  };

  const handleNew = () => {
    setShowForm(true);
  };

  const handleEdit = (id) => {
    setSelectedRecord(id);
    setShowForm(true);
  };

  useEffect(() => {
    if (!showForm) {
      setSelectedRecord(null);
      // handleSearch();
    }
  }, [showForm]);

  const onClose = (values) => {
    console.log('received values', values);
    console.log('recordData before insert new', recordData);

    if (!values.id) {
      setShowForm(false);
      return;
    }

    const existingRecordIndex = recordData.findIndex((item) => item.id === values.id);

    if (existingRecordIndex === -1) {
      // Add the new record if not found
      setRecordData([values, ...recordData]);
      // setRecordData([...recordData, values]);
    } else {
      // Update the existing record
      const updatedRecords = [...recordData];
      updatedRecords[existingRecordIndex] = values;
      setRecordData(updatedRecords);
    }

    setShowForm(false);
  };

  const handleReceiptClick = (price, description, date, name, cpf) => {
    api
      .post('/orders/receipt', { price, description, date, name, cpf })
      .then((response) => {
        // Abre o PDF em uma nova guia do navegador
        window.open(response.data.original, '_blank');
      })
      .catch((error) => {
        // Lida com erros da requisição
        console.error('Erro ao enviar requisição de recibo:', error);
      });
  };

  useEffect(() => {
    handleSearch();
  }, [searchTerm]);

  const tableColumns = [
    {
      title: t('screens:movements.data.type'),
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <Tag color={record.type === 'input' ? 'green' : 'volcano'}>{record.type === 'input' ? 'Entrada' : 'Saída'}</Tag>
      ),
    },
    {
      title: t('screens:movements.data.due_date'),
      dataIndex: 'due_date',
      key: 'due_date',
      render: (text, record) => (record.due_date ? moment(record.due_date).format('DD/MM/YYYY') : '-'),
    },
    {
      title: t('screens:movements.data.pay_day'),
      dataIndex: 'pay_day',
      key: 'pay_day',
      render: (text, record) => (record.pay_day ? moment(record.pay_day).format('DD/MM/YYYY') : '-'),
    },
    {
      title: t('screens:movements.data.value'),
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: t('screens:movements.data.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('screens:movements.data.provider_id'),
      dataIndex: 'provider.name',
      key: 'provider.name',
      render: (text, record) =>
        record.provider && record.provider.company_name
          ? record.provider.company_name
          : record.provider && record.provider.contact && record.provider.contact.name,
    },
    {
      title: t('screens:movements.data.person_id'),
      dataIndex: 'person.name',
      key: 'person.name',
      render: (record, text) => text.person && text.person.name,
    },
    {
      title: t('screens:movements.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          {record.attachment_id ? (
            <Button size="small" title="Anexo" disabled>
              <FaFilePdf />
            </Button>
          ) : (
            <Button size="small" title="Anexo" style={{ visibility: 'hidden' }}>
              <FaFilePdf />
            </Button>
          )}
          {record.type === 'input' && record.paid_out && (
            <Button
              size="small"
              title="Recibo"
              onClick={() =>
                handleReceiptClick(
                  record.value,
                  record.description,
                  record.created_at,
                  record.person.name,
                  record.person.cpf
                )}
            >
              <FaRegNewspaper />
            </Button>
          )}
          <Can permission="@movement/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record.id)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@movement/edit">
            <Can permission="@movement/view">
              <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
          <Can permission="@movement/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];

  return (
    <>
      <TableHeader>
        {/* align itens in right side */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'line',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            width: '420px',
          }}
        >
          <Can permission="@movement/edit">
            <Button loading={loading} color="primary" onClick={handleNew}>
              <FaPlus />
              {t('screens:movements.btnNew')}
            </Button>
          </Can>
          <Input.Search onSearch={(value) => setSearchTerm(value)} className="search-field" />
        </div>
      </TableHeader>
      <Table pagination={false} rowKey="id" loading={loading} dataSource={recordData} columns={tableColumns} />
      <Pagination
        onChange={(value) => {
          handleChangePage(value);
        }}
        meta={meta}
      />

      <Global />

      <MovementForm
        visible={showForm}
        movementID={selectedRecord}
        personId={personId}
        personName={personName}
        onClose={onClose}
      />
    </>
  );
}
