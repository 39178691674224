import React, { useState, useEffect } from 'react';
import { FaPlus, FaRegTrashAlt, FaPencilAlt, FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { Input, message, Popconfirm } from 'antd';
import { normalizeString } from '~/Utils';
import api from '~/services/api';
import errorHandler from '~/Utils/errorHandler';
import PageTitle from '~/components/PageTitle';
import Button from '~/components/Button';
import { TableHeader, Table, TableActions } from '~/components/Table';
import Box from '~/components/Box';
import Can from '~/components/Can';
import Cannot from '~/components/Cannot';
import moment from 'moment';
import DefaultLayout from '../_layouts/full';
import ManagementForm from './form';
import { Global } from './styles';

export default function Management() {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]); // eslint-disable-line
  const [allTableData, setAllTableData] = useState([]); // eslint-disable-line
  const [selectedRecord, setSelectedRecord] = useState({}); // eslint-disable-line
  const [searchTerm, setSearchTerm] = useState('');
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();

  const fetchRecords = async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/management');
      console.log('data', data);
      if (data.length !== 0) {
        setTableData(data);
        setAllTableData(data);
      } else setTableData([]);
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleNew = () => {
    setShowForm(true);
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setShowForm(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await api.delete(`management/${id}`);
      message.success(t('messages:success'));
      fetchRecords();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!showForm) {
      setSelectedRecord({});
      fetchRecords();
    }
  }, [showForm]);

  useEffect(() => {
    fetchRecords();
  }, []);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      const filteredData = allTableData.filter((record) => {
        const name = record.name ? normalizeString(record.name) : '';
        const description = record.description ? normalizeString(record.description) : '';
        const term = normalizeString(searchTerm);

        return name.includes(term) || description.includes(term);
      });
      setTableData(filteredData);
    } else {
      fetchRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const tableColumns = [
    {
      title: t('screens:managements.data.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('screens:managements.data.description'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: t('screens:managements.data.signature'),
      key: 'signature',
      render: (text, record) => (
        // show image
        <>
          {/* <>{record.signature_url}</> */}
          <img src={record.signature_url} alt="signature" style={{ width: '300px' }} />
        </>
      ),
    },
    {
      title: t('screens:managements.data.initial_date'),
      key: 'initial_date',
      render: (text, record) => <span>{moment(record.initial_date).format('DD/MM/YYYY')}</span>,
    },
    {
      title: t('screens:managements.data.final_date'),
      key: 'final_date',
      render: (text, record) => (
        <span>
          {record.final_date ? moment(record.final_date).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: t('screens:managements.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        <TableActions>
          <Can permission="@user/edit">
            <Button size="small" title="Editar" onClick={() => handleEdit(record)}>
              <FaPencilAlt />
            </Button>
          </Can>
          <Cannot permission="@user/edit">
            <Can permission="@user/view">
              <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleEdit(record.id)}>
                <FaEye />
              </Button>
            </Can>
          </Cannot>
          <Can permission="@user/delete">
            <Popconfirm
              title={t('messages:confirmDelete')}
              okText={t('messages:yes')}
              onConfirm={() => handleDelete(record.id)}
              cancelText={t('messages:no')}
            >
              <Button size="small" title="Excluir">
                <FaRegTrashAlt />
              </Button>
            </Popconfirm>
          </Can>
        </TableActions>
      ),
    },
  ];

  return (
    <DefaultLayout>
      <PageTitle title={t('screens:managements.title')}>
        <Can permission="@user/edit">
          <Button loading={loading} color="primary" onClick={handleNew}>
            <FaPlus />
            {t('screens:managements.btnNew')}
          </Button>
        </Can>
      </PageTitle>
      <Box>
        <TableHeader>
          <Input.Search onSearch={(value) => setSearchTerm(value)} className="search-field" />
        </TableHeader>
        <Table rowKey="id" loading={loading} dataSource={tableData} columns={tableColumns} />
        <Global />
        {showForm && (
          <ManagementForm
            visible={showForm}
            selectedRecord={selectedRecord}
            onClose={() => {
              setShowForm(false);
              setSelectedRecord({});
            }}
          />
        )}
      </Box>
    </DefaultLayout>
  );
}
