/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Modal, Spin, Tag, message } from 'antd';
import { FaEye, FaRegTrashAlt } from 'react-icons/fa';

import errorHandler from '~/Utils/errorHandler';

import { Table, TableHeader, TableActions } from '~/components/Table';
import Box from '~/components/Box';
import Button from '~/components/Button';
import { ModalFooter } from '~/components/Modal';
import { normalizeString, getColorByAbstractStatus } from '~/Utils';
import api from '~/services/api';

import AbstractForm from './form';

export default function Abstract({ visible, onClose, congressID }) {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    setLoading(true);
    try {
      const { congress } = recordData[0];

      if (!congress) {
        return;
      }
      const { slug } = congress;
      await api
        .get(`/congresses/exportResumesBy/${congress.id}`, {
          method: 'GET',
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download', `resumos_${slug}_${new Date().toLocaleDateString()}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchRecordData = async () => {
    setLoading(true);
    try {
      if (congressID) {
        const { data } = await api.get(`/congresses/getResumes/${congressID}`);
        setRecordData(data);
      }
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await fetchRecordData();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);

    try {
      await api.delete(`/congresses/delete-resumes/${id}`);
      message.success("Resumo deletado com sucesso!");
      fetchRecordData();
    } catch (error) {
      errorHandler(error);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      const results = recordData.filter(
        (item) =>
          (item.person && item.person.name.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) !== -1) ||
          (item.person &&
            normalizeString(item.person.name).toLowerCase().indexOf(searchTerm.toString().toLowerCase()) !== -1) ||
          (item.evaluator && item.evaluator.name.toLowerCase().indexOf(searchTerm.toString().toLowerCase()) !== -1) ||
          (item.evaluator &&
            normalizeString(item.evaluator.name).toLowerCase().indexOf(searchTerm.toString().toLowerCase()) !== -1)
      );
      setTableData(results);
    } else {
      setTableData(recordData);
    }
  }, [recordData, searchTerm]);

  const handleView = (resume) => {
    setShowForm(true);
    setSelectedRecord(resume);
  };

  const tableColumns = [
    {
      title: t('screens:abstracts.data.title'),
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
    },
    {
      title: t('fields:person_id'),
      dataIndex: 'person_id',
      key: 'person_id',
      render: (record, text) => text.person && text.person.name,
      ellipsis: true,
    },
    {
      title: t('fields:evaluator_id'),
      dataIndex: 'evaluator_id',
      key: 'evaluator_id',
      render: (record, text) => text.evaluator && text.evaluator.name,
      ellipsis: true,
    },
    {
      title: t('screens:abstracts.data.subarea'),
      dataIndex: 'subArea.name',
      key: 'subArea.name',
      render: (text, record) => record.subArea && record.subArea.name,
      ellipsis: true,
    },
    {
      title: t('screens:abstracts.data.updated_at'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: t('screens:abstracts.data.words'),
      dataIndex: 'word',
      key: 'word',
      ellipsis: true,
      render: (text, record) => {
        let words;
        if (record.word_1) {
          words = `${record.word_1}; `;
        }
        if (record.word_2) {
          words += `${record.word_2}; `;
        }
        if (record.word_3) {
          words += `${record.word_3}; `;
        }
        if (record.word_4) {
          words += `${record.word_4}; `;
        }
        if (record.word_5) {
          words += `${record.word_5}; `;
        }
        return words;
      },
    },
    {
      title: t('screens:abstracts.data.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <Tag color={getColorByAbstractStatus(record.status)}>{record.status}</Tag>,
    },
    {
      title: t('screens:abstracts.data.actions'),
      dataIndex: 'actions',
      key: 'actions',
      width: '140px',
      align: 'center',
      render: (text, record) => (
        // console.log('Congress/Abstract/index => acitons', record),
        <div style={{ display: 'flex' }}>
          <TableActions>
            <Button style={{ margin: 'auto' }} size="small" title="Visualizar" onClick={() => handleView(record)}>
              <FaEye />
            </Button>
          </TableActions>
          <TableActions>
            <Button size="small" title="Excluir" onClick={() => handleDelete(record.id)}>
              <FaRegTrashAlt />
            </Button>
          </TableActions>
        </div>
      ),
    },
  ];

  return (
    <Modal
      title={t('screens:abstracts.title')}
      onCancel={onClose}
      visible={visible}
      loading={loading}
      centered
      width="1250px"
      footer={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <>
          <Button
            disabled={recordData && recordData.length === 0}
            loading={loading}
            onClick={handleExport}
            style={{ position: 'absolute' }}
          >
            {t('screens:subscriptions.export')}
          </Button>
          <ModalFooter loading={loading} type="info" onCancel={onClose} />
        </>
      }
    >
      <Spin spinning={loading}>
        <Box>
          <TableHeader>
            <Input.Search onSearch={(value) => setSearchTerm(value)} className="search-field" />
          </TableHeader>
          <Table rowKey="id" loading={loading} dataSource={tableData} columns={tableColumns} />
        </Box>

        <AbstractForm
          visible={showForm}
          resume={selectedRecord}
          onClose={() => {
            setShowForm(false);
            setSelectedRecord(null);
          }}
        />
      </Spin>
    </Modal>
  );
}
