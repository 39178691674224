/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Input, Select, DatePicker, Switch } from 'formik-antd';
import { Modal, message, Spin, Col } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import 'moment/locale/pt-br';
import moment from 'moment';
import InputCurrency from '~/components/Form/InputCurrency';
import errorHandler from '~/Utils/errorHandler';
import Row from '~/components/Row';
import FormControl from '~/components/Form/FormControl';
import { ModalFooter } from '~/components/Modal';
import { decrypt, dynamicCost, getSuccessMessageByModule } from '~/Utils/index';

import api from '~/services/api';

const initialValues = {
  description: '',
  professor: '',
  url: '',
  info: '',
  date_time: moment(),
  free: false,
  bank_account_id: '',
  cost_center_id: '',
  congress_id: '',
  graduation_student_value: 0.0,
  graduation_student_associated_value: 0.0,
  postdoctoral_student_associated_value: 0.0,
  postdoctoral_student_value: 0.0,
  professional_value: 0.0,
  professional_associated_value: 0.0,
  pos_graduation_student_value: 0.0,
  pos_graduation_student_associated_value: 0.0,
};

export default function CourseForm({ visible, onClose, courseID }) {
  const { t } = useTranslation();
  const [recordData, setRecordData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [congressOptions, setCongressOptions] = useState([]);
  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [bankAccountOptions, setbankAccountOptions] = useState([]);
  const { permissions } =
    JSON.parse(decrypt(localStorage.getItem('@App:user'))) !== null &&
    JSON.parse(decrypt(localStorage.getItem('@App:user')));

  const fetchCongresses = async () => {
    const { data } = await api.get('/congresses');
    setCongressOptions(data);
  };

  const fetchCostCenters = async () => {
    try {
      const { data } = await api.get('/costCenters');
      setCostCenterOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const fetchBankAccounts = async () => {
    try {
      const { data } = await api.get('/bankAccounts');
      setbankAccountOptions(data);
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleSave = async (values, { setErrors }) => {
    setLoading(true);
    values.date_time = moment(values.date_time).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    console.log('values before save: ', values);
    delete values.isEdit;
    try {
      if (values.id) {
        console.log('values to save: ', values);
        await api.put(`/courses/${values.id}`, values);
        message.success(getSuccessMessageByModule(t('screens:courses.titleSingular'), t('messages:successUpdated')));
      } else {
        await api.post('/courses', values);
        message.success(getSuccessMessageByModule(t('screens:courses.titleSingular'), t('messages:successCreated')));
      }
      onClose();
    } catch (error) {
      setErrors(errorHandler(error));
    }
    setLoading(false);
  };

  const fetchRecord = async () => {
    if (!courseID) {
      setRecordData(initialValues);
    } else {
      const response = await api.get(`/courses/${courseID}`);
      const data = JSON.parse(response.data);
      console.log('data from course: ', data);
      setRecordData(data);
    }
  };

  const fetchScreenData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchCostCenters(), fetchCongresses(), fetchBankAccounts()]);
      await fetchRecord();
    } catch (error) {
      errorHandler(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (visible) {
      fetchScreenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const CourseSchema = Yup.object().shape({
    description: Yup.string().required(),
    professor: Yup.string().nullable(),
    info: Yup.string().required(),
    url: Yup.string()
      .nullable()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Insira um link válido!'
      ),
    date_time: Yup.date().required(),
    free: Yup.bool().required(),
    cost_center_id: Yup.string().nullable(),
    bank_account_id: Yup.string().nullable(),
    congress_id: Yup.number().integer().required('Congresso campo obrigatório'),

    // if values.free is true, all values below should be 0
    graduation_student_value: Yup.number().when('free', {
      is: true,
      then: Yup.number().min(0).required(),
      otherwise: Yup.number().min(0.01).required(),
    }),
    graduation_student_associated_value: Yup.number().min(0).required(),
    professional_value: Yup.number().when('free', {
      is: true,
      then: Yup.number().min(0).required(),
      otherwise: Yup.number().min(0.01).required(),
    }),
    professional_associated_value: Yup.number().min(0).required(),
    pos_graduation_student_value: Yup.number().when('free', {
      is: true,
      then: Yup.number().min(0).required(),
      otherwise: Yup.number().min(0.01).required(),
    }),
    pos_graduation_student_associated_value: Yup.number().min(0).required(),
    postdoctoral_student_associated_value: Yup.number().min(0).required(),
    postdoctoral_student_value: Yup.number().when('free', {
      is: true,
      then: Yup.number().min(0).required(),
      otherwise: Yup.number().min(0.01).required(),
    }),
  });

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={recordData}
      enableReinitialize
      onSubmit={handleSave}
      validationSchema={CourseSchema}
    >
      {({ errors, isSubmitting, submitForm, setValues, setFieldValue, values, resetForm }) => (
        <Modal
          title={recordData.id ? `${t('screens:editing')}: ${recordData.description}` : t('screens:courses.title')}
          onCancel={onClose}
          style={{ maxWidth: '950px' }}
          afterClose={resetForm}
          visible={visible}
          loading={loading || isSubmitting}
          centered
          width="90%"
          footer={(
            <ModalFooter
              type={permissions && !permissions.includes('@course/edit') && 'info'}
              onOk={submitForm}
              loading={loading || isSubmitting}
              onCancel={onClose}
            />
          )}
        >
          <Spin spinning={loading || isSubmitting}>
            <Form>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Row>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.description}
                      field="description"
                      label={t('screens:courses.data.description')}
                      required
                    >
                      <Input name="description" disabled={permissions && !permissions.includes('@course/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 12 }}
                      error={errors.professor}
                      field="professor"
                      label={t('screens:courses.data.professor')}
                    >
                      <Input name="professor" disabled={permissions && !permissions.includes('@course/edit')} />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.info}
                      field="info"
                      label={t('screens:courses.data.info')}
                      required
                    >
                      <Input.TextArea
                        rows={3}
                        name="info"
                        disabled={permissions && !permissions.includes('@congress/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.max_subscriptions}
                      field="max_subscriptions"
                      label={`${t('screens:congresses.data.max_subscriptions')} ${
                        values.free === true ? '' : '(pagas)'
                      }`}
                    >
                      <Input
                        name="max_subscriptions"
                        disabled={permissions && !permissions.includes('@congress/edit')}
                      />
                    </FormControl>
                    <FormControl cols={{ xs: 10 }} error={errors.url} field="url" label={t('screens:courses.data.url')}>
                      <Input name="url" disabled={permissions && !permissions.includes('@course/edit')} />
                    </FormControl>
                    <FormControl
                      error={errors.date_time}
                      cols={{ xs: 8 }}
                      field="date_time"
                      required
                      label={t('fields:date_time')}
                    >
                      <DatePicker
                        format="DD/MM/YYYY HH:mm:ss"
                        showTime
                        name="date_time"
                        placeholder={t('messages:select')}
                        locale={locale}
                        disabled={permissions && !permissions.includes('@course/edit')}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 24 }}
                      error={errors.congress_id}
                      field="congress_id"
                      label={t('fields:congress_id')}
                      required
                    >
                      {/* Caso exista necessidade de alterar congresso de curso já existente, deverá ser validado no backend. */}
                      <Select
                        name="congress_id"
                        disabled={recordData.id || (permissions && !permissions.includes('@course/edit'))}
                        allowClear
                        optionFilterProp="children"
                      >
                        {congressOptions &&
                          congressOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.description.toUpperCase()} - {item.place}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      cols={{ xs: 3 }}
                      field="free"
                      error={errors.free}
                      label={t('screens:congresses.data.free')}
                    >
                      <Switch
                        name="free"
                        checkedChildren={t('messages:yes')}
                        unCheckedChildren={t('messages:no')}
                        disabled={permissions && !permissions.includes('@congress/edit')}
                        onChange={() => {
                          if (values.free !== true) {
                            setFieldValue('graduation_student_value', 0.0);
                            setFieldValue('graduation_student_associated_value', 0.0);
                            setFieldValue('postdoctoral_student_value', 0.0);
                            setFieldValue('postdoctoral_student_associated_value', 0.0);
                            setFieldValue('professional_value', 0.0);
                            setFieldValue('professional_associated_value', 0.0);
                            setFieldValue('pos_graduation_student_value', 0.0);
                            setFieldValue('pos_graduation_student_associated_value', 0.0);
                          } else {
                            // set original values
                            setFieldValue('graduation_student_value', recordData.graduation_student_value);
                            setFieldValue('graduation_student_associated_value', recordData.graduation_student_associated_value);
                            setFieldValue('postdoctoral_student_value', recordData.postdoctoral_student_value);
                            setFieldValue('postdoctoral_student_associated_value', recordData.postdoctoral_student_associated_value);
                            setFieldValue('professional_value', recordData.professional_value);
                            setFieldValue('professional_associated_value', recordData.professional_associated_value);
                            setFieldValue('pos_graduation_student_value', recordData.pos_graduation_student_value);
                            setFieldValue('pos_graduation_student_associated_value', recordData.pos_graduation_student_associated_value);
                          }
                        }}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 10 }}
                      error={errors.bank_account_id}
                      field="bank_account_id"
                      label={t('fields:bank_account_id')}
                      required={!values.free}
                    >
                      <Select
                        name="bank_account_id"
                        disabled={values.free === true || (permissions && !permissions.includes('@course/edit'))}
                        allowClear
                        optionFilterProp="children"
                      >
                        {bankAccountOptions &&
                          bankAccountOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.description} - {item.bank}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      cols={{ xs: 10 }}
                      error={errors.cost_center_id}
                      field="cost_center_id"
                      label={t('fields:cost_center_id')}
                      required={!values.free}
                    >
                      <Select
                        name="cost_center_id"
                        disabled={values.free === true || (permissions && !permissions.includes('@course/edit'))}
                        allowClear
                        optionFilterProp="children"
                      >
                        {costCenterOptions &&
                          costCenterOptions.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.graduation_student_value}
                      field="graduation_student_value"
                      label={t('fields:graduation_student_value')}
                      required={!values.free}
                    >
                      <InputCurrency
                        name="graduation_student_value"
                        disabled={values.free === true || (permissions && !permissions.includes('@course/edit'))}
                        currency="R$"
                        number={false}
                        maxLength="9"
                        onChange={(event) => {
                          setTimeout(() => {
                            setValues({ ...values, graduation_student_value: dynamicCost(event.target.value) });
                          }, 100);
                        }}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.graduation_student_associated_value}
                      field="graduation_student_associated_value"
                      label={t('fields:graduation_student_associated_value')}
                      required={!values.free}
                    >
                      <InputCurrency
                        name="graduation_student_associated_value"
                        disabled={values.free === true || (permissions && !permissions.includes('@course/edit'))}
                        currency="R$"
                        number={false}
                        maxLength="9"
                        onChange={(event) => {
                          setTimeout(() => {
                            setValues({
                              ...values,
                              graduation_student_associated_value: dynamicCost(event.target.value),
                            });
                          }, 100);
                        }}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.postdoctoral_student_value}
                      field="postdoctoral_student_value"
                      label={t('fields:postdoctoral_student_value')}
                      required={!values.free}
                    >
                      <InputCurrency
                        name="postdoctoral_student_value"
                        disabled={values.free === true || (permissions && !permissions.includes('@course/edit'))}
                        currency="R$"
                        number={false}
                        maxLength="9"
                        onChange={(event) => {
                          setTimeout(() => {
                            setValues({ ...values, postdoctoral_student_value: dynamicCost(event.target.value) });
                          }, 100);
                        }}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.postdoctoral_student_associated_value}
                      field="postdoctoral_student_associated_value"
                      label={t('fields:postdoctoral_student_associated_value')}
                      required={!values.free}
                    >
                      <InputCurrency
                        name="postdoctoral_student_associated_value"
                        disabled={values.free === true || (permissions && !permissions.includes('@course/edit'))}
                        currency="R$"
                        number={false}
                        maxLength="9"
                        onChange={(event) => {
                          setTimeout(() => {
                            setValues({
                              ...values,
                              postdoctoral_student_associated_value: dynamicCost(event.target.value),
                            });
                          }, 100);
                        }}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.professional_value}
                      field="professional_value"
                      label={t('fields:professional_value')}
                      required={!values.free}
                    >
                      <InputCurrency
                        name="professional_value"
                        disabled={values.free === true || (permissions && !permissions.includes('@course/edit'))}
                        currency="R$"
                        number={false}
                        maxLength="9"
                        onChange={(event) => {
                          setTimeout(() => {
                            setValues({ ...values, professional_value: dynamicCost(event.target.value) });
                          }, 100);
                        }}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.professional_associated_value}
                      field="professional_associated_value"
                      label={t('fields:professional_associated_value')}
                      required={!values.free}
                    >
                      <InputCurrency
                        name="professional_associated_value"
                        currency="R$"
                        number={false}
                        maxLength="9"
                        disabled={values.free === true || (permissions && !permissions.includes('@course/edit'))}
                        onChange={(event) => {
                          setTimeout(() => {
                            setValues({ ...values, professional_associated_value: dynamicCost(event.target.value) });
                          }, 100);
                        }}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.pos_graduation_student_value}
                      field="pos_graduation_student_value"
                      label={t('fields:pos_graduation_student_value')}
                      required={!values.free}
                    >
                      <InputCurrency
                        name="pos_graduation_student_value"
                        currency="R$"
                        number={false}
                        maxLength="9"
                        disabled={values.free === true || (permissions && !permissions.includes('@course/edit'))}
                        onChange={(event) => {
                          setTimeout(() => {
                            setValues({ ...values, pos_graduation_student_value: dynamicCost(event.target.value) });
                          }, 100);
                        }}
                      />
                    </FormControl>
                    <FormControl
                      cols={{ xs: 6 }}
                      error={errors.pos_graduation_student_associated_value}
                      field="pos_graduation_student_associated_value"
                      label={t('fields:pos_graduation_student_associated_value')}
                      required={!values.free}
                    >
                      <InputCurrency
                        name="pos_graduation_student_associated_value"
                        currency="R$"
                        number={false}
                        maxLength="9"
                        disabled={values.free === true || (permissions && !permissions.includes('@course/edit'))}
                        onChange={(event) => {
                          setTimeout(() => {
                            setValues({
                              ...values,
                              pos_graduation_student_associated_value: dynamicCost(event.target.value),
                            });
                          }, 100);
                        }}
                      />
                    </FormControl>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      )}
    </Formik>
  );
}
